import React from 'react'

const ScheduleEmailBtn = (props) => {
    return (
      <svg
        className={props.className}
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.789 7.69438C17.7889 7.54285 17.7279 7.39141 17.6174 7.28098L15.4453 5.10887V0.585938C15.4453 0.262344 15.183 0 14.8594 0H3.14062C2.81703 0 2.55469 0.262344 2.55469 0.585938V5.10887L0.382578 7.28098C0.380547 7.28301 0.378828 7.28527 0.376836 7.28734C0.267031 7.40047 0.211211 7.54941 0.210977 7.69438C0.210977 7.69477 0.210938 7.69512 0.210938 7.69551V18.2422C0.210938 19.2127 1.00063 20 1.96875 20H16.0312C17.0015 20 17.7891 19.2107 17.7891 18.2422V7.69551C17.7891 7.69512 17.789 7.69477 17.789 7.69438ZM15.4453 6.76613L16.3745 7.69531L15.4453 8.62449V6.76613ZM3.72656 5.35176C3.72656 5.35164 3.72656 5.35148 3.72656 5.35137V1.17188H14.2734V5.35137C14.2734 5.35148 14.2734 5.35164 14.2734 5.35176V9.79637L11.1011 12.9688H6.89895L3.72656 9.79637V5.35176ZM2.55469 6.76613V8.62449L1.62551 7.69531L2.55469 6.76613ZM1.38281 17.9995V9.10988L5.82762 13.5547L1.38281 17.9995ZM2.21148 18.8281L6.89895 14.1406H11.1011L15.7885 18.8281H2.21148ZM16.6172 17.9995L12.1724 13.5547L16.6172 9.10988V17.9995Z"
          fill="#646464"
        />
      </svg>
    );
}

export default ScheduleEmailBtn

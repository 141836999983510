import React from 'react';

function Features() {
  return (
    <>
      <p>Features</p>
    </>
  );
}

export default Features;


const dashBoardType = {
    FETCH_DASHBOARD_LOADING: 'FETCH_DASHBOARD_LOADING',
    FETCH_DASHBOARD_SUCCESS: 'FETCH_DASHBOARD_SUCCESS',

    FETCH_DASHBOARD_CARD_1_LOADING: 'FETCH_DASHBOARD_CARD_1_LOADING',
    FETCH_DASHBOARD_CARD_1_SUCCESS: 'FETCH_DASHBOARD_CARD_1_SUCCESS',

    FETCH_DASHBOARD_CARD_2_LOADING: 'FETCH_DASHBOARD_CARD_2_LOADING',
    FETCH_DASHBOARD_CARD_2_SUCCESS: 'FETCH_DASHBOARD_CARD_2_SUCCESS',

    FETCH_DASHBOARD_CARD_3_LOADING: 'FETCH_DASHBOARD_CARD_3_LOADING',
    FETCH_DASHBOARD_CARD_3_SUCCESS: 'FETCH_DASHBOARD_CARD_3_SUCCESS',

    FETCH_DEMAND_LOADING: 'FETCH_DEMAND_LOADING',
    FETCH_DEMAND_SUCCESS: 'FETCH_DEMAND_SUCCESS',

    FETCH_BLENDEDCOST_ENERGY_LOADING: 'FETCH_BLENDEDCOST_ENERGY_LOADING',
    FETCH_BLENDEDCOST_ENERGY_SUCCESS: 'FETCH_BLENDEDCOST_ENERGY_SUCCESS'
}

export default dashBoardType;
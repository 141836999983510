import React from 'react';

function TwitterIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='24'
      fill='none'
      viewBox='0 0 25 24'
    >
      <path
        fill='#143861'
        fillRule='evenodd'
        d='M22.018 6.146a7.598 7.598 0 01-2.185.599 3.82 3.82 0 001.673-2.103 7.67 7.67 0 01-2.418.923 3.805 3.805 0 00-6.483 3.469 10.8 10.8 0 01-7.84-3.975A3.775 3.775 0 004.25 6.97c0 1.32.672 2.485 1.692 3.166a3.805 3.805 0 01-1.723-.477v.047a3.808 3.808 0 003.051 3.73 3.856 3.856 0 01-1.002.134c-.246 0-.484-.025-.717-.071a3.806 3.806 0 003.554 2.642 7.633 7.633 0 01-4.725 1.626c-.307 0-.61-.018-.908-.052a10.753 10.753 0 005.832 1.712c6.998 0 10.824-5.797 10.824-10.824l-.013-.493a7.599 7.599 0 001.903-1.965z'
        clipRule='evenodd'
      ></path>
      <mask width='20' height='16' x='3' y='4' maskUnits='userSpaceOnUse'>
        <path
          fill='#fff'
          fillRule='evenodd'
          d='M22.018 6.146a7.598 7.598 0 01-2.185.599 3.82 3.82 0 001.673-2.103 7.67 7.67 0 01-2.418.923 3.805 3.805 0 00-6.483 3.469 10.8 10.8 0 01-7.84-3.975A3.775 3.775 0 004.25 6.97c0 1.32.672 2.485 1.692 3.166a3.805 3.805 0 01-1.723-.477v.047a3.808 3.808 0 003.051 3.73 3.856 3.856 0 01-1.002.134c-.246 0-.484-.025-.717-.071a3.806 3.806 0 003.554 2.642 7.633 7.633 0 01-4.725 1.626c-.307 0-.61-.018-.908-.052a10.753 10.753 0 005.832 1.712c6.998 0 10.824-5.797 10.824-10.824l-.013-.493a7.599 7.599 0 001.903-1.965z'
          clipRule='evenodd'
        ></path>
      </mask>
    </svg>
  );
}

export default TwitterIcon;


const reportType = {
    FETCH_REPORT_LOADING: 'FETCH_REPORT_LOADING',
    FETCH_REPORT_SUCCESS: 'FETCH_REPORT_SUCCESS',
    FETCH_REPORT_BASELINE_LOADING: 'FETCH_REPORT_BASELINE_LOADING',
    FETCH_REPORT_BASELINE_SUCCESS: 'FETCH_REPORT_BASELINE_SUCCESS',
    CHANGE_DATE_TYPE: 'CHANGE_DATE_TYPE',
    CHANGE_DATE: 'CHANGE_DATE'
}

export default reportType;

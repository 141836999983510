import React from 'react'

function About() {
  return (
    <>
      <p>About</p>
    </>
  )
}

export default About

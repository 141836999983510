import React from 'react';

function PdfIcon({className}) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='20'
      fill='none'
      viewBox='0 0 16 20'
    >
      <path
        fill='#646464'
        d='M15.378 4.289L11.21.122A.417.417 0 0010.917 0h-8.75C1.247 0 .5.747.5 1.667v16.666c0 .92.747 1.667 1.667 1.667h11.666c.92 0 1.667-.747 1.667-1.667V4.583a.416.416 0 00-.122-.294zm-4.045-2.866l2.745 2.744h-1.911a.834.834 0 01-.834-.834v-1.91zm3.334 16.91c0 .46-.374.834-.834.834H2.167a.834.834 0 01-.834-.834V1.667c0-.46.374-.834.834-.834H10.5v2.5c0 .92.748 1.667 1.667 1.667h2.5v13.333z'
      ></path>
      <path
        fill='#646464'
        d='M10.124 12.232a12.075 12.075 0 01-.996-.86c-.317-.317-.6-.625-.846-.918.383-1.184.551-1.795.551-2.12 0-1.384-.5-1.667-1.25-1.667-.57 0-1.25.296-1.25 1.706 0 .622.34 1.377 1.016 2.254-.165.504-.36 1.086-.577 1.741a9.547 9.547 0 01-.34.875 6.953 6.953 0 00-.285.134c-.331.166-.646.315-.938.453C3.88 14.46 3 14.877 3 15.7c0 .597.649.967 1.25.967.775 0 1.945-1.035 2.8-2.778.886-.35 1.989-.61 2.86-.772.697.536 1.467 1.05 1.84 1.05 1.033 0 1.25-.598 1.25-1.099 0-.985-1.126-.985-1.667-.985-.168 0-.619.05-1.209.149zM4.25 15.833a.658.658 0 01-.417-.134c0-.295.881-.713 1.733-1.116l.165-.078c-.626.908-1.245 1.328-1.481 1.328zm2.917-7.46c0-.873.27-.873.416-.873.295 0 .417 0 .417.833 0 .176-.117.616-.332 1.302-.327-.504-.501-.936-.501-1.262zm.32 4.479l.075-.22c.154-.464.294-.88.418-1.256.173.19.359.386.559.585.078.078.272.254.53.474-.514.112-1.06.25-1.583.416zm4.68.216c0 .188 0 .265-.387.268-.113-.025-.376-.18-.7-.4.118-.013.204-.02.253-.02.616 0 .79.06.834.152z'
      ></path>
    </svg>
  );
}

export default PdfIcon;

import React from 'react';

function PrintIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      fill='none'
      viewBox='0 0 20 20'
    >
      <path
        fill='#646464'
        d='M3.793 0C2.843 0 2.07.775 2.07 1.724v5.173h-.345C.774 6.897 0 7.67 0 8.62v6.207c0 .949.775 1.724 1.724 1.724h.765l-.41 1.64a.346.346 0 00-.01.084c0 .95.775 1.724 1.724 1.724h12.414a1.9 1.9 0 001.28-.503c.34-.315.56-.798.434-1.305l-.41-1.64h.765c.95 0 1.724-.775 1.724-1.724V8.62c0-.95-.775-1.724-1.724-1.724h-.345V5.172a.345.345 0 00-.092-.234L13.356.11a.345.345 0 00-.253-.11h-9.31zm0 .69h8.62v4.482c0 .19.155.345.346.345h4.482v1.38H2.76V1.724c0-.579.455-1.034 1.034-1.034zm9.31.162l3.693 3.976h-3.693V.852zm-9.655.355a.172.172 0 00-.172.172v3.793a.172.172 0 10.345.005V1.552h2.241a.172.172 0 10.005-.345H3.448zm-1.724 6.38h16.552c.579 0 1.034.455 1.034 1.034v6.207c0 .579-.455 1.034-1.034 1.034h-.938l-.797-3.187a.345.345 0 00-.334-.261H3.793a.345.345 0 00-.335.261l-.796 3.187h-.938A1.025 1.025 0 01.69 14.828V8.62c0-.58.455-1.035 1.034-1.035zm1.38 2.068a.345.345 0 100 .69h10.344a.345.345 0 100-.69H3.103zm11.724 0a.345.345 0 100 .69.345.345 0 000-.69zm1.034 0a.345.345 0 100 .69.345.345 0 000-.69zm1.035 0a.345.345 0 100 .69.345.345 0 000-.69zM4.062 13.103h11.876l1.314 5.257c.058.234-.029.44-.234.63-.205.19-.525.32-.811.32H3.793c-.568 0-1.015-.44-1.031-1.004l1.3-5.203zm3.18 1.552a.172.172 0 10-.005.345H12.759a.172.172 0 10.005-.345H7.24zm-1.38 1.724a.172.172 0 10-.005.345h8.281a.172.172 0 10.005-.345H5.862zm-.69 1.724a.172.172 0 00-.004.345h9.66a.172.172 0 10.005-.345H5.171z'
      ></path>
    </svg>
  );
}

export default PrintIcon;

import React from 'react';

function InstagramIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='24'
      fill='none'
      viewBox='0 0 25 24'
    >
      <path
        fill='#143861'
        fillRule='evenodd'
        d='M8.4 2.182H17.2a5.425 5.425 0 015.419 5.419V16.4a5.425 5.425 0 01-5.42 5.418H8.4A5.425 5.425 0 012.981 16.4V7.601a5.425 5.425 0 015.42-5.419zM17.2 20.076a3.68 3.68 0 003.677-3.677V7.601a3.681 3.681 0 00-3.677-3.677H8.4a3.68 3.68 0 00-3.676 3.677v8.798A3.68 3.68 0 008.4 20.076H17.2zM7.657 12A5.149 5.149 0 0112.8 6.857 5.149 5.149 0 0117.942 12a5.149 5.149 0 01-5.142 5.143A5.149 5.149 0 017.657 12zm1.77 0a3.376 3.376 0 003.373 3.372A3.376 3.376 0 0016.172 12a3.376 3.376 0 00-3.373-3.372A3.376 3.376 0 009.428 12z'
        clipRule='evenodd'
      ></path>
      <mask width='21' height='20' x='2' y='2' maskUnits='userSpaceOnUse'>
        <path
          fill='#fff'
          fillRule='evenodd'
          d='M8.4 2.182H17.2a5.425 5.425 0 015.419 5.419V16.4a5.425 5.425 0 01-5.42 5.418H8.4A5.425 5.425 0 012.981 16.4V7.601a5.425 5.425 0 015.42-5.419zM17.2 20.076a3.68 3.68 0 003.677-3.677V7.601a3.681 3.681 0 00-3.677-3.677H8.4a3.68 3.68 0 00-3.676 3.677v8.798A3.68 3.68 0 008.4 20.076H17.2zM7.657 12A5.149 5.149 0 0112.8 6.857 5.149 5.149 0 0117.942 12a5.149 5.149 0 01-5.142 5.143A5.149 5.149 0 017.657 12zm1.77 0a3.376 3.376 0 003.373 3.372A3.376 3.376 0 0016.172 12a3.376 3.376 0 00-3.373-3.372A3.376 3.376 0 009.428 12z'
          clipRule='evenodd'
        ></path>
      </mask>
    </svg>
  );
}

export default InstagramIcon;
